export enum DEFAULT_LANGUAGES_ENUM {
  CA = 'ca',
  EN = 'en',
  ES = 'es',
}
export enum LOCAL_STORAGE_KEYS {
  LANGUAGES = 'languages',
  LAST_GEOGRAPHIC_AREA_ID = 'LAST_GEOGRAPHIC_AREA_ID',
  RELATIVE_EMAIL = 'RELATIVE_EMAIL',
  SSO_KEY = 'ssoKey',
  SSO_PROVIDER = 'ssoProvider',
  USER_EMAIL = 'userEmail',
  USER_LANGUAGE = 'userLanguage',
  USER_ROLE = 'userRole',
}

export enum LANGUAGES_ENUM {
  CA_ES = 'ca_ES',
  EN_GB = 'en_GB',
  ES_ES = 'es_ES',
  ES_MX = 'es_MX',
  PL_PL = 'pl_PL',
}

export const enum USER_ROLES_ENUM {
  ADMIN = 'ADMIN',
  RELATIVE = 'RELATIVE',
  SCHOOL = 'SCHOOL',
  STUDENT = 'STUDENT',
  STUDENT_UNDER_14 = 'STUDENT_UNDER_14',
  TEACHER = 'TEACHER',
}

export const enum STATUS_ERROR {
  FORBIDDEN = 403,
  PRECONDITION_FAILED = 412,
  INTERNAL_SERVER_ERROR = 500,
}

export const enum SEARCH_PARAMS {
  CUSTOM1 = 'custom1',
  CUSTOM2 = 'custom2',
  ID = 'id',
  KEY = 'key',
  PROVIDER = 'provider',
  RF = 'rf',
  USER_ID = 'u',
}

export const enum TYPE_EXCEPTION_LOGIN {
  BAD_CREDENTIAL = 'BadCredentialsException',
  DELETED_USERDeletedUserException = 'DeletedUserException',
  PENDING_VERIFY = 'PendingVerifyUserException',
  UNAUTHORIZED = 'unauthorized',
  USER_UNLINKED = 'user_unlinked',
}

export const enum GRAFANA_VIEWS {
  APPLICATIONS = 'applications',
  CHECK_YOUR_INBOX = 'check-your-inbox',
  CONTACT_US = 'contact-us',
  HOME = 'home',
  INTEGRATIONS = 'integrations',
  PASSWORD_RECOVERY = 'password-recovery',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
}
