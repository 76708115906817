import i18n, { use } from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { LANGUAGES_ENUM, LOCAL_STORAGE_KEYS } from 'shared/utils/constants'

use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}.json?cb=${new Date().getTime()}`,
    },
    debug: false,
    fallbackLng:
      localStorage.getItem(LOCAL_STORAGE_KEYS?.USER_LANGUAGE) ||
      LANGUAGES_ENUM?.ES_ES,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    nsSeparator: false,
    requestOptions: {
      cache: 'no-cache',
    },
  })

export default i18n
