import {
  ReactNode,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Route,
  useLocation,
  Navigate,
  useParams,
  useSearchParams,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from 'react-router-dom'
import { routesList } from 'routes'
import SnackbarContextProvider from 'shared/contexts/SnackbarContext'
import Spinner from 'shared/components/Spinner'
import { LANGUAGES_ENUM, SEARCH_PARAMS } from 'shared/utils/constants'
import { checkLogin } from 'shared/services/user'

const LanguageWrapper = ({ children }: { children: ReactNode }) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  useLayoutEffect(() => {
    if (lang === 'en') {
      navigate(pathname.replace('en', LANGUAGES_ENUM.EN_GB).concat(search))
    }
    if (lang === 'es') {
      navigate(pathname.replace('es', LANGUAGES_ENUM.ES_ES).concat(search))
    }
    if (lang === 'ca') {
      navigate(pathname.replace('ca', LANGUAGES_ENUM.CA_ES).concat(search))
    }
  }, [])

  useLayoutEffect(() => {
    if (lang !== language) {
      changeLanguage(lang ?? LANGUAGES_ENUM.ES_ES)
    }
  }, [lang])

  return children
}

const RouterWrapper = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const returnForwardUrl = searchParams.get(SEARCH_PARAMS.RF)
  const { pathname } = useLocation()

  const [showLoader, setShowLoader] = useState(true)

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: 'instant',
      left: 0,
      top: 0,
    })
  }, [pathname])

  useEffect(() => {
    checkLogin()
      .then(({ loggedIn }) => {
        if (loggedIn) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          window.location.href = returnForwardUrl ?? (window as any).def.url
        }
      })
      .finally(() => {
        setShowLoader(false)
      })
  }, [])

  if (showLoader) {
    return <Spinner />
  }

  return children
}

const App = () => {
  const {
    i18n: { language },
  } = useTranslation()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {routesList.map(({ Component, path }) => (
          <Route
            element={
              <RouterWrapper>
                <SnackbarContextProvider>
                  <LanguageWrapper>
                    <Component />
                  </LanguageWrapper>
                </SnackbarContextProvider>
              </RouterWrapper>
            }
            key={path}
            path={path}
          />
        ))}
        ,
        <Route element={<Navigate replace to={`/${language}/`} />} path='*' />,
      </>,
    ),
  )

  const setHtmlLanguage = () => {
    const { pathname } = window.location
    const language = pathname.split('/', 2).filter(Boolean).toString()
    document.documentElement.lang = language
  }

  return (
    <>
      {setHtmlLanguage()}
      <Suspense fallback={<Spinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  )
}

export default App
