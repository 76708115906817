import { createRoot } from 'react-dom/client'
import './index.css'
import GlobalFonts from '@web-ui-kit/fonts'
import reportWebVitals from './reportWebVitals'

import 'config/i18n'
import App from 'app/App'
import GrafanaFaro from 'shared/components/GrafanaFaro'

declare global {
  interface Window {
    eduBook: {
      appleStoreUrls: { [key: string]: string }
      desktopAppInstallerUrl: { [key: string]: string }
      googlePlayStoreUrls: { [key: string]: string }
      moodlePlugins: { [key: string]: string }
    }
    languages: {
      enableMxLanguage: boolean
    }
    legalVV: {
      cookiesPolicyUrls: { [key: string]: string }
      legalNoticeUrls: { [key: string]: string }
      privacyPolicyUrls: { [key: string]: string }
    }
    zendesk: {
      baseUrls: { [key: string]: string }
      installationGuidesUrls: { [key: string]: { [key: string]: string } }
    }
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <>
    <GlobalFonts />
    <GrafanaFaro>
      <App />
    </GrafanaFaro>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
